import React from "react";
import useStyles from "./styles";
import {Button, Card, CardActions, CardContent, CardMedia, Typography} from "@material-ui/core";
import moment from "moment";
import {Delete, MoreHoriz, ThumbUpAlt, ThumbUpAltOutlined} from "@material-ui/icons";
import {deletePost, likePost, setSelectedPost} from "../../postsSlice";
import {useDispatch, useSelector} from "react-redux";

const Post = ({post}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const profile = useSelector(state => state.auth.profile);

    const Likes = () => {
        if (post.likes.length > 0) {
            return post.likes.find((like) => like === (profile?.result?.googleId || profile?.result?._id))
                ? (
                    <><ThumbUpAlt
                        fontSize="small"/>&nbsp;{post.likes.length > 2 ? `You and ${post.likes.length - 1} others` : `${post.likes.length} like${post.likes.length > 1 ? 's' : ''}`}</>
                ) : (
                    <><ThumbUpAltOutlined
                        fontSize="small"/>&nbsp;{post.likes.length} {post.likes.length === 1 ? 'Like' : 'Likes'}</>
                );
        }

        return <><ThumbUpAltOutlined fontSize="small"/>&nbsp;Like</>;
    };

    return (
        <Card className={classes.card}>
            <CardMedia className={classes.media} image={post.selectedFile} title={post.title}/>
            <div className={classes.overlay}>
                <Typography variant="h6">{post.name}</Typography>
                <Typography variant="body1">{moment(post.createdAt).fromNow()}</Typography>
            </div>
            {(profile?.result?.googleId === post?.creator || profile?.result?._id === post?.creator) && (
                <div className={classes.overlay2}>
                    <Button style={{color: 'white'}} size="small" onClick={() => dispatch(setSelectedPost(post))}>
                        <MoreHoriz fontSize='medium'/>
                    </Button>
                </div>
            )}
            <div className={classes.details}>
                <Typography variant="body2" color="textSecondary">{post.tags.map((tag) => `#${tag} `)}</Typography>
            </div>
            <Typography className={classes.title} variant="h5" gutterBottom>{post.title}</Typography>
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">{post.message}</Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button size="small" color="primary" disabled={!profile?.result}
                        onClick={() => dispatch(likePost(post._id))}>
                    <Likes/>
                </Button>
                {(profile?.result?.googleId === post?.creator || profile?.result?._id === post?.creator) && (
                    <Button size="small" color="primary" onClick={() => dispatch(deletePost(post._id))}>
                        <Delete fontSize="small"/> Delete
                    </Button>
                )}
            </CardActions>
        </Card>
    );
}

export default Post;