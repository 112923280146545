import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import API, {loadProfileFromStorage, saveProfileToStorage} from "../../api";

export const login = createAsyncThunk('auth/login', async (authData) => {
    saveProfileToStorage(authData);
    return authData;
});

export const logout = createAsyncThunk('auth/logout', async () => {
    saveProfileToStorage(null)
    return null;
});

export const signin = createAsyncThunk('auth/signin', async (userData) => {
    const response = await API.post('/users/signin', userData);
    saveProfileToStorage(response.data);
    return response.data;
});

export const signup = createAsyncThunk('auth/signup', async (userData) => {
    const response = await API.post('/users/signup', userData);
    saveProfileToStorage(response.data);
    return response.data;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        profile: loadProfileFromStorage(),
    },
    reducers: {},
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.profile = action.payload;
        },
        [logout.fulfilled]: (state, action) => {
            state.profile = null;
        },
        [signin.fulfilled]: (state, action) => {
            state.profile = action.payload;
        },
        [signup.fulfilled]: (state, action) => {
            state.profile = action.payload;
        }
    }
});

export default authSlice.reducer;