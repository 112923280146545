import axios from "axios";

const URL = "https://memories-backend.onrender.com/";
// const URL = "http://localhost:5000";
const STORAGE_PROFILE = "profile";

export function loadProfileFromStorage() {
    return JSON.parse(localStorage.getItem(STORAGE_PROFILE));
}

export function saveProfileToStorage(profile) {
    if (profile) {
        localStorage.setItem(STORAGE_PROFILE, JSON.stringify({...profile}));
    } else {
        localStorage.removeItem(STORAGE_PROFILE);
    }
}

const API = axios.create({baseURL: URL});

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')){
        req.headers.authorization = `Bearer ${loadProfileFromStorage().token}`;
    }
    return req;
});

export default API;