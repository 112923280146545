import React, {useEffect, useState} from "react";
import useStyles from "./styles";
import {Button, Paper, TextField, Typography} from "@material-ui/core";
import FileBase from "react-file-base64";
import {useDispatch, useSelector} from "react-redux";
import {addPost, setSelectedPost, updatePost} from "../postsSlice";

const initialPostData = {title: '', message: '', tags: '', selectedFile: ''};

const Form = () => {
    const classes = useStyles();
    const [postData, setPostData] = useState(initialPostData);
    const selectedPost = useSelector((state) => state.posts.selectedPost);
    const profile = useSelector(state => state.auth.profile);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedPost) setPostData(selectedPost);
        else setPostData(initialPostData);
    }, [selectedPost]);

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            selectedPost && selectedPost._id ?
                updatePost({id: selectedPost._id, post: {...postData, name: profile?.result?.name}}) :
                addPost({...postData, name: profile?.result?.name}))
            .then((result) => {
                if (result?.payload)
                    clear();
            });
    };

    const clear = () => {
        dispatch(setSelectedPost(null));
        setPostData(initialPostData);
    };

    if (!profile?.result?.name) {
        return (
            <Paper className={classes.paper}>
                <Typography variant="h6" align="center">
                    Please Sign In to create your own memories and like other's memories.
                </Typography>
            </Paper>
        );
    }

    return (
        <Paper className={classes.paper}>
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
                <Typography variant="h6">{selectedPost ? 'Editing' : 'Creating'} a Memory</Typography>
                <TextField name="title" variant="outlined" label="Title" fullWidth
                           value={postData.title}
                           onChange={(e) => setPostData({...postData, title: e.target.value})}/>
                <TextField name="message" variant="outlined" label="Message" fullWidth
                           value={postData.message}
                           onChange={(e) => setPostData({...postData, message: e.target.value})}/>
                <TextField name="tags" variant="outlined" label="Tags (coma separated)" fullWidth
                           value={postData.tags}
                           onChange={(e) => setPostData({...postData, tags: e.target.value.split(',')})}/>
                <div className={classes.fileInput}>
                    <FileBase type="file" multiple={false}
                              onDone={({base64}) => setPostData({...postData, selectedFile: base64})}/>
                </div>
                <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit"
                        fullWidth>Submit</Button>
                <Button variant="contained" color="secondary" size="small" fullWidth onClick={clear}>Clear</Button>
            </form>
        </Paper>
    );
}

export default Form;