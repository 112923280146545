import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import API from "../api";

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
    const response = await API.get('/posts');
    return response.data;
});

export const addPost = createAsyncThunk('posts/addPost', async (newPost) => {
    const response = await API.post('/posts', newPost);
    return response.data;
});

export const updatePost = createAsyncThunk('posts/updatePost', async ({id, post}) => {
    const response = await API.patch(`/posts/${id}`, post);
    return response.data;
});

export const deletePost = createAsyncThunk('posts/deletePost', async (id) => {
    await API.delete(`/posts/${id}`);
    return id;
});

export const likePost = createAsyncThunk('posts/likePost', async (id) => {
    const response = await API.patch(`/posts/${id}/likePost`);
    return response.data;
});

export const postsSlice = createSlice({
    name: 'posts',
    initialState: {
        posts: [],
        selectedPost: null
    },
    reducers: {
        setSelectedPost: (state, action) => {
            state.selectedPost = action.payload;
        }
    },
    extraReducers: {
        [fetchPosts.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.posts = action.payload;
        },
        [addPost.fulfilled]: (state, action) => {
            state.posts = [...state.posts, action.payload];
        },
        [deletePost.fulfilled]: (state, action) => {
            state.posts = state.posts.filter((post) => post._id !== action.payload);
            if (state.selectedPost && state.selectedPost._id === action.payload)
                state.selectedPost = null;
        },
        [updatePost.fulfilled]: (state, action) => {
            state.posts = state.posts.map((post) => post._id === action.payload._id ? action.payload : post);
        },
        [likePost.fulfilled]: (state, action) => {
            state.posts = state.posts.map((post) => post._id === action.payload._id ? action.payload : post);
        }
    }
});

export const {setSelectedPost} = postsSlice.actions;

export default postsSlice.reducer;