import {configureStore} from "@reduxjs/toolkit";
import postsReducer from "./components/postsSlice";
import authReducer from "./components/Auth/authSlice";

export default configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
    reducer: {
        auth: authReducer,
        posts: postsReducer
    }
});