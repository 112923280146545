import React, {useState} from 'react';
import useStyles from "./styles";
import GoogleLogin from "react-google-login";
import {Avatar, Button, Container, Grid, Paper, Typography} from "@material-ui/core";
import {LockOutlined} from "@material-ui/icons";
import Input from "./Input";
import Icon from "./Icon";
import {login, signin, signup} from "./authSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const initialUserData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
};

const Auth = () => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const [userData, setUserData] = useState(initialUserData);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const switchMode = () => {
        setIsSignup((prevState) => !prevState);
        setShowPassword(false);
    };

    const googleSuccess = async (res) => {
        const result = res?.profileObj;
        const token = res?.tokenId;

        try {
            dispatch(login({result, token}))
                .then(() => setUserData(initialUserData))
                .then(() => navigate('/'));
        } catch (error) {
            console.log(error);
        }
    };

    const googleFailure = (error) => {
        console.log(error);
        console.log('Google Sign In was unsuccessful. Try again later.')
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            dispatch(isSignup ?signup(userData) : signin(userData))
                .unwrap()
                .then(result => {
                    if (result) {
                        navigate('/');
                    }
                })
                .catch((rejectedError) => console.log(rejectedError));
        } catch(error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value});
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                    <LockOutlined/>
                </Avatar>
                <Typography variant="h5">{isSignup ? 'Sign Up' : 'Sign In'}</Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {isSignup && (
                            <>
                                <Input name="firstName" label="First Name" autoFocus half handleChange={handleChange}/>
                                <Input name="lastName" label="Last Name" half handleChange={handleChange}/>
                            </>
                        )}
                        <Input name="email" label="Email Address" type="email" handleChange={handleChange}/>
                        <Input name="password" label="Password" type={showPassword ? "text" : "password"}
                               handleChange={handleChange}
                               handleShowPassword={() => setShowPassword((prevState) => !prevState)}/>
                        {isSignup && (
                            <Input name="confirmPassword" label="Repeat Password" type="password"
                                   handleChange={handleChange}/>
                        )}
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        {isSignup ? 'Sign Up' : 'Sign In'}
                    </Button>
                    <GoogleLogin clientId="36945892015-qmnjsptseanv55pu2719mtm2vr92fihq.apps.googleusercontent.com"
                                 render={(renderProps) => (
                                     <Button className={classes.googleButton} color="primary" fullWidth
                                             onClick={renderProps.onClick}
                                             disabled={renderProps.disabled} startIcon={<Icon/>} variant="contained">
                                         Google Sign in
                                     </Button>
                                 )}
                                 onSuccess={googleSuccess}
                                 onFailure={googleFailure}
                                 cookiePolicy="single_host_origin"
                    />
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button
                                onClick={switchMode}>{isSignup ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default Auth;
